/* global Component, md5 */
class itemModalSliderComponent extends Component {

    static name() {
        return "itemModalSliderComponent";
    }

    static componentName() {
        return "itemModalSliderComponent";
    }

    getProps() {
        /*
            showCarusel:     muestra las miniaturas abajo de la imagen principal si hay mas de una
            slideBigImage:   muestra las flechas a los costados de la imagen principal si hay mas de una
        */
        return ['item','container',"stockFlag","showCarusel", "slideBigImage", "itemInSlider"];
    }

    data() {
        return {
            slidesItem:[],
            selected:0,
            minIndex:0,
            openModal:false
        };
    }

    mounted() {
        return function () {
            this.getItemImage();
            this.subscribeEvent('modalItemCick',this.toogleModal);
        };
    }

    getComputed() {
        return {
            noStock:function () {
                if(this.item.Qty <= 0 && this.stockFlag)
                    return true;
                return false;
            },
            maxIndex:function (){
                if(this.itemInSlider)
                    this.itemInSlider;
                return 4;
            }
        };
    }

    toogleModal(){
        if(this.openModal)
            $(this.$refs.itemImgModal).modal('hide');
        else
            $(this.$refs.itemImgModal).modal('show');
        this.openModal = !this.openModal;
    }

    getMethods() {
        return {
            toogleModal:this.toogleModal,
            selectImage:this.selectImage,
            getItemImage:this.getItemImage,
            shouldRenderImg:this.shouldRenderImg,
            moveLeft:this.moveLeft,
            moveRight:this.moveRight
        };
    }

    selectImage(index){
        this.selected = index;
    }

    getItemImage(){
        if(this.item && this.item.slideIds) {
            let imagesList = [];
            let slideValue = this.item.slideIds;
            if(!Array.isArray(slideValue) && typeof slideValue === 'string'){
                imagesList = this.item.slideIds.split(",");
            }
            else{
                imagesList = this.item.slideIds;
            }

            for (let imgId of imagesList) {
                let dataDesTruct = imgId.split("--");
                let is360  = false;
                if(dataDesTruct.length > 1)
                    is360 = dataDesTruct[1] === "360";
                this.slidesItem.push({src:`/oo/api/get_attached_file?OriginRecordName=Item&OriginId=${this.item.Code}&id=${imgId}&ts=${md5(dataDesTruct[0])}`,is360:is360});
            }
            return this.slidesItem[0];
        }
        return [];
    }

    shouldRenderImg(index) {
        return (index >= this.minIndex && index < this.minIndex + this.maxIndex);
    }

    moveLeft(){
        if(this.minIndex > 0) {
            this.minIndex = this.minIndex - 1;
            this.selected = this.minIndex;
        }
    }

    moveRight(){
        if(this.minIndex + 1 < this.slidesItem.length) {
            this.minIndex = this.minIndex + 1;
        }
        else {
            this.minIndex =  0;
        }
        this.selected = this.minIndex;
    }



    getTemplate() {
        return `<div class="modal fade" ref="itemImgModal" tabindex="-1" role="dialog" aria-hidden="true">
                    <div class="modal-dialog modal-full" role="document">
                         <div class="container container-modal-img">
                             <button class="control-left" @click="moveLeft">
                                <i class="icon fa fa-arrow-left text-white" /> 
                             </button>
                             <template v-if="noStock==true">
                                <img class="no-stock-image" src="../static/ecommerce/img/sin_stock.gif" width="140px" height="140px" style="position: absolute;">
                             </template>
                             <template v-if="slidesItem.length>0">
                                <template v-if="container">
                                    <span class="slider-item-control-left big-image-control" v-if="slideBigImage && slidesItem.length > 1" @click="moveLeft()"><i class="fas fa-chevron-left fa-2x"></i></span>
                                        <imgProxyComponent :is360="slidesItem[selected].is360" myclass="item-image img-fluid" :isthumbnails="false" :src="slidesItem[selected].src" mystyle="min-height:190px;"/>
                                    <span class="slider-item-control-right big-image-control" v-if="slideBigImage && slidesItem.length > 1" @click="moveRight()"><i class="fas fa-chevron-right fa-2x"></i></span>
                                </template>
                                <template v-else>
                                    <imgProxyComponent :is360="slidesItem[selected].is360" myclass="item-image img-fluid" :isthumbnails="false" :src="slidesItem[selected].src" />
                                </template>
                             </template>
                             <template v-else>
                                <imgProxyComponent />
                             </template>
                             <button class="control-right"@click="moveRight">
                                <i class="icon fa fa-arrow-right text-white" />
                             </button>
                         </div>
                         
                    </div>
              </div>`;
    }
}

itemModalSliderComponent.registerComponent();
